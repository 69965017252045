import React, { useContext, useEffect, useState } from 'react'
import LazyLoadBgImage from '../LazyLoadBgImage'
import { sContext } from '../../../context'
import ReactMarkdown from '../../../lib/ReactMarkdown'
import EntriesSection from '../EntriesSection'
import { getLandscapeAsset } from '../../../lib/getAssets'
import AnchorTag from '../AnchorTag'

const FeatureSingleStory = ({
  entry,
  entry: {
    path,
    categoryPath,
    title,
    subtitle,
    articleType,
    topType,
    banner,
    overrideBanner,
    inArticleAsset,
    landscapeDesktopAsset,
    landscapeMobileAsset,
    uuid
  }
}) => {
  const localePath = useContext(sContext).currentLocale
  const [isMobileBlock, setIsMobileBlock] = useState(null)
  const [asset, setAsset] = useState({})
  const sizes = [
    [0, 480],
    [320, 640],
    [640, 960],
    [960, 1280],
    [1200, 2048],
  ].reverse()
  const windowSize =  typeof window !== 'undefined' ? window.innerWidth : 0
  const width = sizes.find(s => s[0] <= windowSize)[1]

  const handleResize = () => {
    if (window.innerWidth < 575) {
      if(!isMobileBlock) {
        setIsMobileBlock(true)
      }

    } else {
      if(isMobileBlock) {
        setIsMobileBlock(false)
      }
    }
  }

  useEffect(() => {
    setAsset(getLandscapeAsset({
      topType,
      banner,
      overrideBanner,
      inArticleAsset,
      landscapeDesktopAsset,
      landscapeMobileAsset
    }))
  }, [topType, inArticleAsset, landscapeDesktopAsset, landscapeMobileAsset, banner, overrideBanner])

  useEffect(() => {
    if(typeof window === undefined) { return }
    handleResize()
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [isMobileBlock])

  if(isMobileBlock) {
    return (
      <>
        <EntriesSection
          section={{
            entries: [{
              fields: {
                ...entry,
                asset: asset || {}
              },
            }],
            type: 'medium-block'
          }} />
          <hr/>
      </>
    )

  } else {
    return (
      <LazyLoadBgImage
        className="row tophero-main"
        asset={asset || {}}
        overrides={{ w: width, q: 70 }}
        gradient="radial" >
        <article
          className="col-lg-12"
          aria-labelledby={uuid} >
          <h3 className="row tophero-tag">
            <AnchorTag
              isAggTag
              articleType={articleType}
              localePath={localePath} >
              {articleType}
            </AnchorTag>
          </h3>
          <div className="row tophero-title">
            <AnchorTag
              categoryPath={categoryPath}
              path={path}
              localePath={localePath}
              attributes={{
                className: 'tophero-title-content'
              }} >
            <h2 id={uuid}>
              <ReactMarkdown
                escapeHtml={false}
                renderers={{
                  text: (node) => {
                    return `${node.value}`.replace(/(\S+)-(\S+)/g,'$1\u2013$2')
                  }
                }}
                source={`${title}${subtitle ? ' — ' : ''}`} />  
            </h2>
            {subtitle && (
              <div className="row tophero-subtitle">
                <ReactMarkdown
                  escapeHtml={false}
                  renderers={{
                      text: (node) => {
                        return `${node.value}`.replace(/(\S+)-(\S+)/g,'$1\u2013$2')
                      }
                    }}
                  source={subtitle} />
              </div>
            )}
            </AnchorTag>
          </div>
        </article>
      </LazyLoadBgImage>
    )
  }
}

export default FeatureSingleStory
