import React from 'react';
import Layout, { buildProps } from './index.js';
import Homepage from './main-components/Homepage';
const Component = (props) => {
  const {
    navigation,
    footer,
    searchedGuides,
    pagePath,
    pageId,
    pages,
    latestIds,
    paths,
    placeOfTheDay
  } = buildProps(props)

  const addedProps = {
    pages,
    navigation,
    footer,
    searchedGuides,
    pagePath,
    pageId,
    paths,
    placeOfTheDay
  }
  return <Homepage
    {...addedProps}
    latestIds={latestIds}
    placeOfTheDay={placeOfTheDay} />
}

const Page = (props) => {
  return <Layout {...props}><Component {...props} /></Layout>
}

export default Page