import React, { useContext, memo } from 'react'
import LazyLoadImage from '../LazyLoadImage'
import { sContext } from '../../../context'
import { Fade } from 'react-reveal'

const PlaceOfTheDay = memo(({ images }) => {
  const localePath = useContext(sContext).currentLocale

  return (
    <div className="cPlaceOfTheDay-wrapper">
      <h2>
        <a
          href='https://www.instagram.com/enroutemag/'
          target="_blank"
          rel="noopener noreferrer" >
          {localePath === 'en' ? 'Inspired? Follow us' : 'Inspiré? Suivez nous'}
        </a>
      </h2>
      <ul>
        {images.map(({
          media_url: url,
          description,
          permalink
        }, i) => (
          <li key={`PlaceOfTheDay-${i}`}>
            <Fade>
            <a
              href={permalink}
              target="_blank"
              rel="noopener noreferrer" >
              <LazyLoadImage
                asset={{
                  url,
                  description
                }}
                className="placeoftheday-image" />
            </a>
            </Fade>
          </li>
        ))}
      </ul>
      <a
        href='https://www.instagram.com/enroutemag/'
        className="instagram-social"
        target="_blank"
        rel="noopener noreferrer"
        aria-label={
          localePath === 'en' ?
            'Navigate to the Instagram profile enroutemag' :
            'Accédez au profil Instagram enroutemag'
        } >
        <span aria-hidden='true'>
          @enroutemag
        </span>
      </a>
    </div>
  )
})

export default PlaceOfTheDay
