import React from 'react'
import Navigation from './components/Navigation'
import Footer from './components/Footer'
import PlaceOfTheDay from './components/Homepage/PlaceOfTheDay'
import FeatureSingleStory from './components/Homepage/FeatureSingleStory'
import EntriesSection from './components/EntriesSection'
import Ads from './components/Ads'
import SponsorModule from './components/sponsor/SponsorModule'

const Wrapper = ({
  children,
  contentType
}) => (
  <div
    className="cHomepage-wrapper"
    data-content-type={contentType} >
    {children}
  </div>
)

const Homepage = ({
  navigation,
  paths,
  pages: {
    navigationColor,
    navigationDefaultColor,
    content
  },
  footer,
  placeOfTheDay
}) => {
  let adsCounter = 1
  
  return (
    <div id="homepage" className="container-fluid">
      <div className="top-ad-wrapper">
        <Ads
          page='homepage'
          counter={1} />
      </div>
      <div className="main-wrapper">
        <Navigation
          navigationColor={navigationColor}
          default={navigationDefaultColor}
          menu={navigation}
          paths={paths || {}}
          hasTopAd
          isHomepage />
        
        <main>
          {content.map(({
            fields: section,
            sys: { contentType: { sys: {
              id: contentType
            }}}
          }, index) => {
            const addedProps = {
              key: `hp-${index}`,
              contentType
            }
            
            switch (contentType) {
              case 'page':
                return (
                  <Wrapper
                    {...addedProps} >
                    <FeatureSingleStory
                      key={'module-' + index}
                      entry={section} />
                  </Wrapper>
                )

              case 'EntriesSection':
                return (
                  <Wrapper
                    {...addedProps} >
                    <EntriesSection section={section} />
                  </Wrapper>
                )

              case 'moduleAd':
                adsCounter++
                return (
                  <Wrapper {...addedProps}>
                    <Ads
                      page='homepage'
                      counter={adsCounter} />
                  </Wrapper>
                )

                case 'Sponsor':
                  return (
                    <Wrapper {...addedProps}>
                      <SponsorModule sponsorData={section} />
                    </Wrapper>
                  )

              default:
                return null
            }
          })}
          {placeOfTheDay?.length > 0 ? (
            <Wrapper contentType={'PlaceOfTheDay'}>
              <PlaceOfTheDay images={placeOfTheDay} />
            </Wrapper>
          ) : ''}
        </main>
        <Footer footer={footer} />
      </div>
    </div>
  )
}

export default Homepage
