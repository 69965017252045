import React from 'react'
import { decorateImagesParams } from '../../lib/utils'

class LazyLoadBgImage extends React.Component {
  ref = React.createRef()
  image = React.createRef()
  observer = typeof window !== 'undefined' && new IntersectionObserver(
    entries => {
      if (entries[0].intersectionRatio > 0) {
        this.observer.disconnect()
        this.setState({
          isVisible: entries[0].intersectionRatio >= 0
        })
      }
    },
    {
      root: null,
      rootMargin: '50px',
      threshold: 0
    }
  )

  state = {
    isVisible: false
  }

  componentDidMount() {
    if(typeof window !== 'undefined') {
      this.observer.observe(this.ref.current)
    }
  }

  componentWillUnmount() {
    if(typeof window !== 'undefined') {
      this.observer.disconnect()
    }
  }

  render() {
    const {
      asset = {},
      overrides,
      gradient,
      usePadding
    } = this.props

    const { file = asset } = asset
    const w =
      file && file.details && file.details.image && file.details.image.width
        ? file.details.image.width
        : 1
    const h =
      file && file.details && file.details.image && file.details.image.height
        ? file.details.image.height
        : 1
    const ratio = (h / w) * 100
    const isVideo = file && file.contentType && `${file.contentType}`.indexOf('video/') === 0

    let appliedGradient = ''
    if(gradient === 'radial') {
      appliedGradient = 'radial-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0)), '
      
    } else if(gradient === 'linear') {
      appliedGradient = 'linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.5)), '
    }
    
    const styles = {
      position: 'relative',
      backgroundImage:
        appliedGradient +
        'url(' +
        (true 
          ? decorateImagesParams((file && file.url) || file || '', overrides)
          : (file && file.lqip || '')) +
        ')'
        + (file && file.lqip ? `,url("${file.lqip}")` : '')

    }
    if (isVideo) {
      styles.backgroundImage = null
    }
    const videoStyles = {
      position: 'absolute',
      zIndex: '1',
      top: '0',
      left: '0',
      right: '0',
      bottom: '0',
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      objectPosition: 'center center'
    }
    const contentStyles = {
      position: 'relative',
      zIndex: 2
    }
    return (
      <div
        ref={this.ref}
        className={`${this.props.className} lazyload-bg-image ${isVideo ? 'is-video' : ''}`}
        id={this.props.id}
        style={styles}
      >
        {usePadding && <div style={{ paddingTop: `${ratio}%` }} />}
        {isVideo && true && (
          <video
            autoPlay='autoplay'
            loop='loop'
            muted
            playsInline
            controls={false}
            className="lazyload-bg-image--video"
            style={videoStyles} >
            <source
              src={file.url}
              type={file.contentType} />
          </video>
        )}
        {!isVideo && (
          <>
            <div
              aria-labelledby='LazyLoadBgImage'
              role='img' />
            <span
              hidden
              id='LazyLoadBgImage'>
              {asset.description}
            </span>
          </>
        )}
        {isVideo && true ? (<div className="lazyload-bg-image--content" style={contentStyles}>{this.props.children}</div>) : this.props.children}
      </div>
    )
  }
}

export default LazyLoadBgImage