import React, { memo, useContext } from 'react';
import { sContext } from '../../../context';
import SponsorSection from './SponsorSection';
import enLogoCBNR from '../../../images/CBNR-2021/CBNR-logo-black.svg';
import frLogoCBNR from '../../../images/CBNR-2021/CBNR-Logo-FR-Black.svg';

const SponsorModule = memo(({
  sponsorData,
  isAeroplan,
  hideHorizontalDivider
}) => {
  const localePath = useContext(sContext).currentLocale;
  const cbnrImage = localePath === 'en' ? enLogoCBNR : frLogoCBNR;
  const cbnrUrl = `https://enroute.aircanada.com/${localePath}/restaurants`

  return (
    <div className={`
      cSponsorModule
      ${isAeroplan ? 'isAeroplan' : ''}
      ${hideHorizontalDivider ? 'hide-horizontal-divider' : ''}
    `}>
      <SponsorSection
        {...sponsorData}
        isAeroplan={isAeroplan}
        cbnrLogo={{
          cbnrImage,
          cbnrUrl
        }} />
    </div>
  );
});

export default SponsorModule;