import React, { memo, useContext } from 'react';
import { sContext } from '../../../context';
import LazyLoadImage from '../LazyLoadImage';

const ImageWrapper = ({ children, link }) => {
  if(!link) return children;

  return (
    <a
      href={link}
      target="_blank"
      rel="noopener noreferrer" >
      {children}
    </a>
  );
};

const SponsorSection = memo(({
  isAeroplan,
  cbnrLogo: {
    cbnrImage,
    cbnrUrl
  },
  includeCbnrLogo,
  firstLevelText,
  firstLevelImage,
  firstLevelLink,
  secondLevelText,
  secondLevelImage,
  secondLevelLink,
  thirdLevelText,
  thirdLevelImage,
  thirdLevelLink
}) => {
  const localePath = useContext(sContext).currentLocale;

  return (
    <div className="cSponsorSection">
      {includeCbnrLogo && (
        <a
          className='cSponsorSection__logo-link'
          href={cbnrUrl}
          target="_blank"
          rel="noopener noreferrer" >
          <LazyLoadImage
            asset={cbnrImage}
            className="cSponsorSection__logo-image"
            sizes={[
              { viewport: 1400, width: 650 },
              { viewport: 0, width: 400 }
            ]}
            ariaLabel={localePath === 'en' ?
              'Canada\'s best new restaurants' :
              'Les meilleurs nouveaux restos canadiens'
            } />
        </a>
      )}
      <div className="cSponsorSection__first">
        {firstLevelText && (
          <span className='cSponsorSection__first-text'>
            {firstLevelText}
          </span>
        )}
        {firstLevelImage && (
          <ImageWrapper link={isAeroplan ? 'https://www.aircanada.com/ca/en/aco/home/aeroplan.html#/' : firstLevelLink}>
            <LazyLoadImage
              asset={firstLevelImage.fields}
              className="cSponsorSection__first-image"
              sizes={[
                { viewport: 1400, width: 650 },
                { viewport: 0, width: 400 }
              ]} />
          </ImageWrapper>
        )}
      </div>
      {secondLevelImage && (
        <div className="cSponsorSection__second">
          {secondLevelText && (
            <span className='cSponsorSection__second-text'>
              {secondLevelText}
            </span>
          )}
          {secondLevelImage && (
            <ImageWrapper link={secondLevelLink}>  
              <LazyLoadImage
                asset={secondLevelImage.fields}
                className="cSponsorSection__second-image"
                sizes={[
                  { viewport: 1400, width: 650 },
                  { viewport: 0, width: 400 }
                ]} />
            </ImageWrapper>
          )}
        </div>
      )}
      {thirdLevelImage && (
        <div className="cSponsorSection__third">
          {thirdLevelText && (
            <span className='cSponsorSection__third-text'>
              {thirdLevelText}
            </span>
          )}
          {thirdLevelImage && (
            <ImageWrapper link={thirdLevelLink}>  
              <LazyLoadImage
                asset={thirdLevelImage.fields}
                className="cSponsorSection__third-image"
                sizes={[
                  { viewport: 1400, width: 650 },
                  { viewport: 0, width: 400 }
                ]} />
            </ImageWrapper>
          )}
        </div>
      )}
    </div>
  );
});

export default SponsorSection;